var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010185" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable &&
                            _vm.popupParam.boardId &&
                            _vm.deletable &&
                            _vm.isOwner
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBLREMOVE",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.removeData },
                                })
                              : _vm._e(),
                            (_vm.editable &&
                              _vm.popupParam.boardId &&
                              _vm.isOwner) ||
                            (_vm.editable && !_vm.popupParam.boardId)
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              label: "LBLTITLE",
                              name: "boardTitle",
                            },
                            model: {
                              value: _vm.data.boardTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardTitle", $$v)
                              },
                              expression: "data.boardTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBLLOCATION",
                              name: "boardLocation",
                            },
                            model: {
                              value: _vm.data.boardLocation,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardLocation", $$v)
                              },
                              expression: "data.boardLocation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              label: "LBL0010183",
                              name: "boardDate",
                            },
                            model: {
                              value: _vm.data.boardDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardDate", $$v)
                              },
                              expression: "data.boardDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              rows: 4,
                              label: "LBL0010182",
                              name: "boardContents",
                            },
                            model: {
                              value: _vm.data.boardContents,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardContents", $$v)
                              },
                              expression: "data.boardContents",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 4,
                              label: "LBL0010186",
                              name: "boardProblem",
                            },
                            model: {
                              value: _vm.data.boardProblem,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardProblem", $$v)
                              },
                              expression: "data.boardProblem",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 4,
                              label: "LBL0010187",
                              name: "boardImprove",
                            },
                            model: {
                              value: _vm.data.boardImprove,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardImprove", $$v)
                              },
                              expression: "data.boardImprove",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 4,
                              label: "LBL0010188",
                              name: "boardBenefit",
                            },
                            model: {
                              value: _vm.data.boardBenefit,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardBenefit", $$v)
                              },
                              expression: "data.boardBenefit",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }